<template>
  <div>
    <button
      class="button button-secondary h-[42px] min-w-[44px] space-x-2 pl-1 pr-1 data-[icon]:!h-10 data-[icon]:!w-10 data-[prefix]:!pl-2.5 data-[suffix]:!pr-2.5 md:min-w-[100px] md:pl-4 md:pr-4 [&>svg]:h-6 [&>svg]:w-6"
      :class="isMovieOnWatchList ? 'border-primary' : 'border-current'"
      @click="handle"
    >
      <InlineSvg
        v-if="isMovieOnWatchList"
        :name="SVG_ICONS.BOOKMARK_FILLED"
        :type="INLINE_SVG_TYPES.ICON"
        :class="isMovieOnWatchList ? 'text-primary' : 'text-current'"
      />

      <InlineSvg
        v-else
        :name="SVG_ICONS.BOOKMARK_OUTLINE"
        :type="INLINE_SVG_TYPES.ICON"
        :class="isMovieOnWatchList ? 'text-primary' : 'text-current'"
      />
      <span class="hidden text-nowrap break-keep md:block" v-text="text" />
    </button>

    <AuthModal
      v-if="isAuthModalVisible"
      :type="USER_AUTH_STATE.LOGIN"
      @close="isAuthModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

const props = defineProps<Props>()

const { t } = useI18n()
const { cookies } = useAccountCookies()

const { add, fetch, remove, movies } = useWatchList()

const isLoggedIn = computed(() => {
  return (
    !!cookies.get(ACCOUNT_ACCESS_TOKEN_COOKIE_NAME) &&
    !!cookies.get(ACCOUNT_REFRESH_TOKEN_COOKIE_NAME)
  )
})

const isAuthModalVisible = ref(false)
const shouldAddAfterLogin = ref(false)

const isMovieOnWatchList = computed(() => {
  return movies.value.some((movie: Movie) => movie.id === props.movie.id)
})

const text = computed(() => {
  return isMovieOnWatchList.value ? t('movieAdded') : t('addMovie')
})

async function handle() {
  if (isLoggedIn.value && isMovieOnWatchList.value) {
    await remove({ movieId: props.movie.id })
  } else if (isLoggedIn.value && !isMovieOnWatchList.value) {
    await add({ movieId: props.movie.id })
    shouldAddAfterLogin.value = false
  } else {
    shouldAddAfterLogin.value = true
    isAuthModalVisible.value = true
  }
}

// Adds the movie to the watch list after the user logs in if they clicked the button before
watch(isLoggedIn, async () => {
  if (shouldAddAfterLogin.value) {
    shouldAddAfterLogin.value = false
    await handle()
  }
})

onMounted(async () => {
  if (isLoggedIn.value) {
    await fetch({})
  }
})

defineOptions({
  name: 'AccountWatchListButton',
})
</script>

<i18n>
de:
  addMovie: 'Film merken'
  movieAdded: 'Film gemerkt'
es:
  addMovie: 'Añadir película'
  movieAdded: 'Película añadida'
</i18n>
